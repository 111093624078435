import React from "react";
import App from "./App"
import {getTokenInfo} from "../helpers/tokenInfo";
import {connect} from "react-redux";
import {PopUpLoader} from "./PopUpLoader";

class CheckCredentials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: !this.props.user.isAuth,
    };
  }

  async componentDidMount() {

    if(!this.props.user.isAuth) {
      getTokenInfo().then(async () => {
        await this.setState({
          loading: false,
        });
      })
    }
  }

  render() {

    if (this.state.loading || !this.props.user.isAuth) {
      return <PopUpLoader active={true} />
    }

    return (
        <App/>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(CheckCredentials);
