import {DialogTitle, Dialog, DialogContent, DialogActions, TextField, MenuItem} from "@material-ui/core";
import React from "react";
import TrelloButton from "../TrelloButton";
import {useSelector} from "react-redux";


export const CreateBoard = (props) => {

    const groups = useSelector(state => state.groups);

    return (
        <Dialog
            open={props.open}
            maxWidth={"sm"}
            fullWidth={true}
            fullScreen={props.width !== 0 && props.width <= 800 }
            onClose={props.onClose}
        >
            <DialogTitle style={{fontFamily:"Avenir", textAlign:"center"}}>Créer un nouveau tableau</DialogTitle>
            <DialogContent style={props.width !== 0 && props.width <= 800 ? {} : {marginBottom:"2rem"}}>
                <form onSubmit={props.handleSubmit}>

                    <TextField
                        margin="normal"
                        size={"small"}
                        fullWidth
                        name={"boardTitle"}
                        type="text"
                        placeholder={"Ajouter un titre au tableau"}
                        value={props.boardTitle}
                        onChange={props.handleChange}
                        label={"Ajouter un titre au tableau"}
                    />

                    <TextField
                        select
                        name={"selectedGroups"}
                        fullWidth
                        margin="normal"
                        label={"Associer à un groupe"}
                        SelectProps={{
                            multiple: true,
                            value: props.selectedGroups ? props.selectedGroups : [],
                            onChange: props.handleChange,
                            type:"select-multiple"
                        }}
                    >
                        {groups.filter(group => props.user.groups.includes(group.id)).map((group, key) =>
                            <MenuItem key={key} value={group.id}>{group.title}</MenuItem>
                        )}
                    </TextField>
                </form>
            </DialogContent>
            <DialogActions>
                <TrelloButton onClick={props.onClose} style={{marginRight:"1rem", backgroundColor:"grey"}}>
                    Annuler
                </TrelloButton>

                <TrelloButton onClick={props.handleSubmit}>
                    Créer un tableau
                </TrelloButton>
            </DialogActions>
        </Dialog>
    )
 }
