import React, {useState} from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import {Draggable} from "react-beautiful-dnd";
import styled from "styled-components";
import Icon from "@material-ui/core/Icon";
import {toggleLoading} from "../actions";
import {connect} from "react-redux";
import TrelloPopup from "./TrelloPopup";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {formatDate} from "../helpers/tools_helper";
import {deleteCardDb} from "../helpers/card_helper";

const CardContainer = styled.div`
  margin: 0 0 8px 0;
  position: relative;
  max-width: 100%;
  word-wrap: break-word;
`;

const DeleteButton = styled(Icon)`
  font-size: 18px !important;
  position: absolute;
  display: none;
  top: 10px;
  right: 10px;
  opacity: 0.5;
  ${CardContainer}:hover & {
    display: block;
    cursor: pointer;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const TrelloCard = React.memo(({title, description, cardState, dueDate, priority, creator, id, listID, index, dispatch, mobinautes, user_has_card}) => {

    const [open, setOpen] = useState(false);

    let priorityColor = {"low": "!", "medium": "!!", "high": '!!!'};
    let stateIcons = {
        "finished": "doneOutlined",
        "in_progress": "forward",
        "not_started": 'close',
        "suspended": "pause"
    };

    /*const PriorityBlock = styled.div`
    background-color: ${priorityColor[priority] ? priorityColor[priority] : 'transparent'};
    width: 18px;
    height: 18px;
    `;*/
    const StyledIcon = styled(Icon)`
    `;

    const handleDeleteCard = () => {
        dispatch(toggleLoading(true));

        deleteCardDb(id);
    };

    const setOpenModal = () => {
        setOpen(true);
    }

    const renderCard = () => {

        return (
            <React.Fragment>
                <Draggable draggableId={String(id)} index={index}>
                    {provided => (
                        <CardContainer
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                        >
                            <Card>

                                <OverlayTrigger
                                    trigger="click"
                                    placement="bottom"
                                    rootClose
                                    overlay={
                                        <Popover id={'popover'}>
                                            <Popover.Header id={'popover-header'} as="h4">Etes-vous sûr de vouloir supprimer ?</Popover.Header>
                                            <Popover.Body id={'popover-body'}>
                                                <button className={'pb deleteBoard'}
                                                        onClick={handleDeleteCard}>Oui
                                                </button>
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <DeleteButton>delete</DeleteButton>
                                </OverlayTrigger>

                                <CardContent style={{padding: '0'}} onClick={setOpenModal}>
                                    <div>
                                        <div className={'DisplayTitleCard'}>
                                            <Typography
                                                style={{
                                                    fontFamily: 'Roboto',
                                                    fontSize: '13px',
                                                    paddingLeft: '0'
                                                }}>{title}</Typography>
                                        </div>
                                        <div className={'DisplayInfoCard'}>
                                            <div className={'left'}>
                                                <Typography
                                                    style={{
                                                        paddingLeft: '0',
                                                        fontSize: '13px'
                                                    }}>{formatDate(dueDate)}</Typography>
                                            </div>
                                            <div className={'right'}>
                                                <StyledIcon style={{fontSize: '18px'}}>
                                                    {stateIcons[cardState]}
                                                </StyledIcon>

                                                {/*<StyledIcon
                                                    style={{fontSize: '18px'}}>{personSelected[person]}
                                                </StyledIcon>*/}

                                                <Typography className={'priority'}>
                                                    {priorityColor[priority]}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>

                                </CardContent>

                                <TrelloPopup
                                    title={title}
                                    description={description}
                                    priority={priority}
                                    state={cardState}
                                    dueDate={dueDate}
                                    creator={creator}
                                    id={id}
                                    listID={listID}
                                    index={index}
                                    dispatch={dispatch}
                                    setOpen={setOpen}
                                    open={open}
                                    mobinautes={mobinautes}
                                    user_has_card={user_has_card}
                                />
                            </Card>
                        </CardContainer>
                    )}
                </Draggable>

            </React.Fragment>
        );
    };

    return renderCard();
});

const mapStateToProps = state => ({
    user: state.user,
});

export default connect(mapStateToProps)(TrelloCard);
