import React from "react";
import {connect} from "react-redux";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {AiOutlineHome} from 'react-icons/ai'
import {BsTrash} from 'react-icons/bs'
import {bindActionCreators} from 'redux';
import {Link, Redirect} from "react-router-dom";
import TrelloList from "./TrelloList";
import TrelloCreate from "./TrelloCreate";
import {sort, setActiveBoard, toggleLoading} from "../actions";
import 'reactjs-popup/dist/index.css';
import {BgColor} from "./BgColor";
import Filter from "./TrelloFilter"
import {OverlayTrigger, Popover} from "react-bootstrap";
import {PopUpLoader} from "./PopUpLoader";
import {deleteBoardDb} from "../helpers/board_helper";

class TrelloBoard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            homeRedirect: false,
            responseCall: null,
            boardId: null,
            mobinautes: [],
            filters: {
                low: false,
                medium: false,
                high: false,
                not_started: false,
                in_progress: false,
                suspended: false,
                finished: false
            }
        };
    }

    updateDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener("resize", this.updateDimensions);

        const {boardID} = this.props.match.params;

        this.props.setActiveBoard(boardID);

        const board = this.props.boards[boardID];

        if (board) {

            await this.handleShapperGroups(board);

            await this.setState({
                boardId:boardID
            })

        } else {

            await this.setState({
                homeRedirect: true
            })
        }
    }

    async handleShapperGroups(board){

        let boardGroups = board.board_has_group;
        let mobinautes = []

        boardGroups.forEach(boardGroup => {
            let group = this.props.groups.find(group => group.id === boardGroup.id_group);
            mobinautes = mobinautes.concat(group.mobinaute.filter(mobinauteDB => mobinautes.every(mobinauteCurrent => mobinauteCurrent.id !== mobinauteDB.id)));
        })

        await this.setState({
            mobinautes: mobinautes
        })
    }

    onDragEnd = result => {
        const {destination, source, draggableId, type} = result;

        if (!destination) {
            return;
        }

        this.props.sort(
            source.droppableId,
            destination.droppableId,
            source.index,
            destination.index,
            draggableId,
            type
        );
    };

    setFilterCards = (newFilters) => {

        this.setState({
            filters: newFilters
        })
    }

    render() {
        if (this.state.homeRedirect) {
            return <Redirect to="/"/>
        } else if (!this.state.boardId) {
            return <PopUpLoader active={true} />;
        }

        const board = this.state.boardId ? this.props.boards[this.state.boardId] : null;
        const listOrder = board.lists;
        const lists = this.props.lists;

        return (
            <div className={'all'} style={{backgroundColor: board.color, height: this.state.height}}>
                <DragDropContext onDragEnd={this.onDragEnd}>

                    {/** HEADER **/}
                    <div className={'header'}>
                        <div className={'header-left'}>

                            <Link to="/" className={'back'}>
                                <button className="navButton">
                                    <AiOutlineHome/>
                                </button>
                            </Link>

                            <div className={'BoardTitle'}>{board.title}</div>

                        </div>
                        <div className={'header-right'}>

                            <OverlayTrigger trigger="click" placement="left" rootClose
                                overlay={
                                    <Popover id={'popover'}>

                                        <Popover.Header id={'popover-header'} as="h4">Etes-vous sûr de vouloir supprimer ? </Popover.Header>

                                        <Popover.Body id={'popover-body'}>

                                            <button className={'pb deleteBoard'} onClick={async () => {this.props.toggleLoading(true); await deleteBoardDb(board)}}>
                                                Oui
                                            </button>

                                        </Popover.Body>
                                    </Popover>
                                }
                            >

                                <button className="navButton">
                                    <BsTrash/>
                                </button>

                            </OverlayTrigger>

                        </div>

                    </div>

                    {/** FILTER **/}

                    <div className={'modify-container'}>

                        <div className={'modify'}>

                            <div className={'filter'}>
                                <Filter setFilter={this.setFilterCards}/>
                            </div>

                            <div className={'bg-color'}><BgColor board={board}/></div>

                        </div>

                    </div>

                    {/** BODY **/}
                    <div className={'body'}>
                        <p>{this.state.responseCall}</p>
                        <Droppable droppableId="all-lists" direction={this.state.width < 750 ? "vertical" : "horizontal"} type="list">

                            {provided => (
                                <div className={'list-container'}
                                     {...provided.droppableProps}
                                     ref={provided.innerRef}
                                >
                                    {listOrder.map((listID, index) => {
                                        const list = lists[listID];
                                        if (list) {
                                            return (
                                                <TrelloList
                                                    open={this.state.open}
                                                    listID={list.id}
                                                    key={list.id}
                                                    title={list.title}
                                                    cards={list.cards}
                                                    index={index}
                                                    filters={this.state.filters}
                                                    mobinautes={this.state.mobinautes}
                                                />
                                            );
                                        } else return '';
                                    })}

                                    {provided.placeholder}

                                    <TrelloCreate list/>

                                </div>
                            )}

                        </Droppable>

                    </div>

                </DragDropContext>

                <PopUpLoader active={this.props.loading} />

            </div>
        );
    }
}

const mapStateToProps = state => ({
    lists: state.lists,
    boards: state.boards,
    user: state.user,
    activeBoard: state.activeBoard,
    loading: state.loading,
    groups: state.groups
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setActiveBoard,
            sort,
            toggleLoading
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TrelloBoard);
