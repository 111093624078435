import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import BoardThumbnail from "../BoardThumbnail";
import {addBoardDb, getBoards} from "../../helpers/board_helper";
import {PopUpLoader} from "../PopUpLoader";
import {bindActionCreators} from "redux";
import {toggleLoading} from "../../actions";
import {HomeHeader} from "./Home.Header"
import {CreateBoard} from "./CreateBoard";
import {getShapperGroups} from "../../helpers/shapper_api_helper";

const HomeContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            height: 0,
            showCreateBoard:false,
            boardTitle:"",
            selectedGroups:[]
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    updateDimensions = () => {
        if((this.state.width !== window.innerWidth || (this.state.height !== window.innerHeight))){
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidUpdate() {
        this.updateDimensions();
    }

    async componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);

        this.props.toggleLoading(true)
        getBoards()
        getShapperGroups()

    }

    handleChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        this.setState({
            [name]:value
        })
    }

    handleSubmit(e) {
        e.preventDefault();

        this.props.toggleLoading(true)

        let newBoard = {
            title: this.state.boardTitle,
            color: "#EA3463",
            list_group: this.state.selectedGroups
        }

        addBoardDb(newBoard)

        this.setState({
            boardTitle: "",
            showCreateBoard: false
        })
    };

    render() {

        const renderBoards = () => {

            return this.props.boardOrder.map(boardID => {
                const board = this.props.boards[boardID];

                if(board){
                    return (
                        <React.Fragment key={boardID}>
                            <Link
                                to={`/${board.id}`}
                                style={{textDecoration: "none"}}
                            >
                                <BoardThumbnail {...board}/>
                            </Link>
                        </React.Fragment>

                    );
                } else return ""
            });
        };

        return (
            <HomeContainer className={'main'} style={{
                width:this.state.width === 0 ? "100%" : this.state.width,
                height:this.state.height === 0 ? "100%" : this.state.height
            }}>

                <HomeHeader>
                    <div className={'navButton'}>{this.props.user.userFirstName.charAt(0)}. {this.props.user.userLastName}</div>
                    <button className={'navButton'} onClick={() => {this.setState({showCreateBoard:true})}}>+ Créer</button>
                </HomeHeader>

                <div style={{padding: "1rem", display:"flex", flexDirection:"column", alignItems:"center"}}>

                    <div className={'myBoards'}>Mes tableaux</div>

                    <div className={'thumbnails'}>{renderBoards()}</div>

                    <CreateBoard
                        open={this.state.showCreateBoard}
                        boardTitle={this.state.boardTitle}
                        onClose={()=> {this.setState({showCreateBoard:false})}}
                        handleChange={this.handleChange}
                        handleSubmit={this.handleSubmit}
                        width={this.state.width}
                        selectedGroups={this.state.selectedGroups}
                        user={this.props.user}
                    />

                </div>

                <PopUpLoader active={this.props.loading} />

            </HomeContainer>
        );
    }
}

const mapStateToProps = state => ({
    boards: state.boards,
    boardOrder: state.boardOrder,
    loading: state.loading,
    user: state.user,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            toggleLoading
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
