import React from "react";
import {Dialog, DialogContent, CircularProgress} from "@material-ui/core";

export const PopUpLoader = (props) => {
    return (
        <Dialog
            open={props.active}
        >
            <DialogContent style={{padding: "30px"}}>
                <CircularProgress style={{color: "black"}}/>
            </DialogContent>
        </Dialog>
    );
}
