import {CONSTANTS} from "./index";
import {deleteList} from "../actions";
import {addBoardDb, changeBgColorDb, deleteBoardDb} from "../helpers/board_helper";

export const setActiveBoard = id => {
  return {
    type: CONSTANTS.SET_ACTIVE_BOARD,
    payload: id
  };
};

export const addBoard = title => {

    addBoardDb(title)

    //dispatchLoader
    return {
        type: CONSTANTS.SET_LOADING,
    };
};

export const deleteBoard = board => {

    board.lists.forEach((list) => {
        deleteList(list)
    })

    deleteBoardDb(board)

    //dispatchLoader
    return {
        type: CONSTANTS.SET_LOADING,
    };
}

export const changeBoardColor = async (boardId, color) => {

    await changeBgColorDb(boardId, color)

    //dispatchLoader
    return {
        type: CONSTANTS.SET_LOADING,
    };
}

export const setBoards = (boards) => {
    // Array de board
    return {
        type: CONSTANTS.SET_BOARDS,
        payload: boards
    }
}

export const setBoardsOrder = (boardOrder) => {
    return {
        type: CONSTANTS.SET_BOARD_ORDER,
        payload: boardOrder
    }
}
