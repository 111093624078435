import { CONSTANTS } from "../actions";

const initialState = [];

const boardOrderReducer = (state = initialState, action) => {
  switch (action.type) {
      case CONSTANTS.ADD_BOARD: {
          return [...state, `board-${action.payload.id}`];
      }

      case CONSTANTS.DELETE_BOARD: {
          const {boardId} = action.payload;
          let index = state.findIndex(element => element === boardId);

          return [
              ...state.slice(0, index),
              ...state.slice(index + 1)
          ];
      }

      case CONSTANTS.SET_BOARD_ORDER: {
          return action.payload;
      }

      default:
          return state;
  }
};

export default boardOrderReducer;
