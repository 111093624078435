import React from 'react';
import {DropdownButton} from "react-bootstrap";

export default class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            low: false,
            medium: false,
            high: false,
            not_started: false,
            in_progress: false,
            suspended: false,
            finished: false
        };
    }

    handleChange = async (e) => {
        let target = e.target;
        let name = target.name
        let check = target.checked

        await this.setState({
            [name]: check
        })

        this.props.setFilter(this.state)
    }

    render() {

        return (
            <div className={'filter'}>
                <DropdownButton id="dropdown-basic-button" title="Trier">
                    <div className={'filter-priority'}>
                        <p className={'filter-title'}>Trier par priorité</p>
                        <label style={{display: 'block'}} key={"low"}>
                            <input
                                type="checkbox"
                                name={"low"}
                                onChange={this.handleChange}
                            />
                            {capitalize("basse")}
                        </label>
                        <label style={{display: 'block'}} key={"medium"}>
                            <input
                                type="checkbox"
                                name={"medium"}
                                onChange={this.handleChange}
                            />
                            {capitalize("moyenne")}
                        </label>
                        <label key={"high"}>
                            <input
                                type="checkbox"
                                name={"high"}
                                onChange={this.handleChange}
                            />
                            {capitalize("haute")}
                        </label>
                    </div>

                    <div className={'filter-state'}>
                        <p className={'filter-title'}>Trier par état</p>
                        <label style={{display: 'block'}} key={"not_started"}>
                            <input
                                type="checkbox"
                                name={"not_started"}
                                onChange={this.handleChange}
                            />
                            {capitalize("non démarrée")}
                        </label>
                        <label style={{display: 'block'}} key={"in_progress"}>
                            <input
                                type="checkbox"
                                name={"in_progress"}
                                onChange={this.handleChange}
                            />
                            {capitalize("en cours")}
                        </label>
                        <label key={"suspended"}>
                            <input
                                type="checkbox"
                                name={"suspended"}
                                onChange={this.handleChange}
                            />
                            {capitalize("suspendue")}
                        </label>
                        <label style={{display: 'block'}} key={"finished"}>
                            <input
                                type="checkbox"
                                name={"finished"}
                                onChange={this.handleChange}
                            />
                            {capitalize("Terminée")}
                        </label>
                    </div>
                </DropdownButton>
            </div>
        )
    }
}

export const capitalize = (s) => s[0].toUpperCase() + s.slice(1)