import {CONSTANTS} from "./index";

export const toggleLoading = (loading) => {
    return (dispatch) => {
        dispatch({
            type: loading ? CONSTANTS.SET_LOADING : CONSTANTS.UNSET_LOADING
        });
    };

};
