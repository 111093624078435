import React from "react";
import Icon from "@material-ui/core/Icon";
import styled from "styled-components";

const TrelloOpenForm = ({ list, children, onClick }) => {
  const buttonTextBackground = list ? "rgba(0,0,0,.15)" : "inherit";

  const OpenFormButton = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Helvetica';
    height: 36px;
    padding: 1rem;
    width: 270px;
    color: white;
    background-color: ${buttonTextBackground};
    border-radius: 0.3rem;
  `;

  return (
    <OpenFormButton onClick={onClick}>
      <Icon>add</Icon>
      <p style={{ flexShrink: 0 }}>{children}</p>
    </OpenFormButton>
  );
};

export default TrelloOpenForm;
