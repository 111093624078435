import {CONSTANTS} from "./index";

export const setUser = (payload) => {

    let userId = (typeof payload != "undefined" ? payload.mobinaute_id : null);
    let userFirstName = (typeof payload != "undefined" ? payload.firstname : null);
    let userLastName = (typeof payload != "undefined" ? payload.lastname : null);
    let appId = (typeof payload != "undefined" ? payload.app_id : null);
    let isAuth = (typeof payload != "undefined" ? payload.isAuth : false);
    let groups = (typeof payload != "undefined" ? payload.groups : null);

    return (dispatch) => {
        dispatch({
            type: CONSTANTS.SET_USER,
            payload: {userId, userFirstName, userLastName, isAuth, appId, groups}
        });
    };
};

export const setToken = (token) => {

    return (dispatch) => {
        dispatch({
            type: CONSTANTS.SET_TOKEN,
            payload: {token: token}
        });
    };
};
