import React, {useState} from "react";
import TrelloCard from "./TrelloCard";
import TrelloCreate from "./TrelloCreate";
import {Droppable, Draggable} from "react-beautiful-dnd";
import {connect} from "react-redux";
import {toggleLoading} from "../actions";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {BsTrash} from "react-icons/bs";
import {deleteListDb, editListTitleDb} from "../helpers/list_helper";

const TrelloList = ({title, cards, listID, index, dispatch, filters, mobinautes}) => {

    const [isEditing, setIsEditing] = useState(false);
    const [listTitle, setListTitle] = useState(title);

    const renderEditInput = () => {
        return (
            <form onSubmit={handleFinishEditing}>
                <input className={'StyledInput'}
                       type="text"
                       value={listTitle}
                       onChange={handleChange}
                       autoFocus
                       onFocus={handleFocus}
                       onBlur={handleFinishEditing}
                    />
                </form>
            );
        };

        const handleFocus = e => {
            e.target.select();
        };

        const handleChange = e => {
            e.preventDefault();
            setListTitle(e.target.value);
        };

    const handleFinishEditing = () => {
        setIsEditing(false);

        dispatch(toggleLoading(true))

        editListTitleDb(listID, listTitle)
    };

    const handleDeleteList = async () => {

        dispatch(toggleLoading(true))

        await deleteListDb(listID);
    };

    return (
        <Draggable draggableId={String(listID)} index={index}>
            {provided => (
                <div className={'ListContainer'}
                     {...provided.draggableProps}
                     {...provided.dragHandleProps}
                     ref={provided.innerRef}
                >
                    <Droppable droppableId={String(listID)} type="card">
                            {provided => (
                                <div>
                                    <div className={'list-header'}>
                                        {isEditing ? (renderEditInput())
                                            : (<div className={'TitleContainer'} onClick={() => setIsEditing(true)}>
                                                <h5 style={{
                                                    marginTop: '10px',
                                                    fontWeight: 'lighter',
                                                    fontSize: '17px'
                                                }}>{listTitle}</h5>
                                            </div>)}
                                        <OverlayTrigger
                                            trigger="click"
                                            placement="bottom"
                                            rootClose
                                            overlay={
                                                <Popover id={'popover'}>
                                                    <Popover.Header id={'popover-header'} as="h4">
                                                        Etes-vous sûr de vouloir supprimer ?
                                                    </Popover.Header>
                                                    <Popover.Body id={'popover-body'}>
                                                        <button
                                                            className={'pb deleteBoard'}
                                                            onClick={async () => {
                                                                await handleDeleteList()
                                                            }}
                                                        >
                                                            Oui
                                                        </button>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <button className={'buttonDelete'}><BsTrash/></button>
                                        </OverlayTrigger>
                                    </div>
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {cards.map(card => (!Object.values(filters).includes(true)) || ((card.state === null || card.priority === null) || (filters[card.priority] || filters[card.card_state])) ?
                                                card : null
                                            // TODO: Vérifier le mutlifiltrage
                                        ).filter(card => card != null).map((card, index) => (
                                            <TrelloCard
                                                key={card.id}
                                                title={card.title}
                                                description={card.description}
                                                priority={card.priority}
                                                cardState={card.card_state}
                                                dueDate={card.due_date}
                                                id={card.id}
                                                index={index}
                                                creator={card.user_creator_id}
                                                listID={listID}
                                                mobinautes={mobinautes}
                                                user_has_card={card.user_has_card}
                                            />
                                        ))}
                                        {provided.placeholder}
                                        <TrelloCreate listID={listID}/>
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </div>
                )}
            </Draggable>
        );
    }
;

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(TrelloList);
