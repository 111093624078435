import React from "react";
import {getTokenInfo} from "../helpers/tokenInfo";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {PopUpLoader} from "./PopUpLoader";

class PrivateRoute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuth: null,
            loading: true,
        };
    }

    async componentDidMount(){

        getTokenInfo().then(async (response) => {
            await this.setState({
                loading: false,
                isAuth: response
            });
        })

    }

    render() {

        if (this.state.loading) {
            return <PopUpLoader active={true} />
        } else {
            if (this.state.isAuth) {
                return (
                    <Route
                        key={this.props.key}
                        path={this.props.path}
                        exact={this.props.exact}
                        name={this.props.name}
                        render={this.props.render}
                    />
                )
            } else {
                return (
                    <Redirect to={'/connexion'}/>
                )
            }
        }

    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(PrivateRoute);
