import {CONSTANTS} from "./index";
import {addListDb, deleteListDb, editListTitleDb} from "../helpers/list_helper";

export const addList = (title) => {

  return (dispatch, getState) => {

    const boardID = getState().activeBoard;

    addListDb(title, boardID)

    //dispatchLoader
    dispatch({
      type: CONSTANTS.SET_LOADING,
    });
  };
};

export const sort = (droppableIdStart, droppableIdEnd, droppableIndexStart, droppableIndexEnd, draggableId, type) => {

  return (dispatch, getState) => {
    const boardID = getState().activeBoard;

    dispatch({
      type: CONSTANTS.DRAG_HAPPENED,
      payload: {droppableIdStart, droppableIdEnd, droppableIndexEnd, droppableIndexStart, draggableId, type, boardID}
    });
  };
};

export const editTitle = (listID, newTitle) => {

  return (dispatch, getState) => {

    editListTitleDb(listID, newTitle)

    //dispatchLoader
    dispatch({
      type: CONSTANTS.SET_LOADING,
    });
  };
};

export const deleteList = listID => {
  return (dispatch) => {

    deleteListDb(listID)

    //dispatchLoader
    dispatch({
      type: CONSTANTS.SET_LOADING,
    });
  };
};

export const setLists = (lists) => {
  // Array de board
  return {
    type: CONSTANTS.SET_LISTS,
    payload: lists
  }
}
