import React, { PureComponent } from "react";
import PrivateRoute from "./PrivateRoute.js";
import Connexion from "./Connexion";
import {Router, Route, Switch} from 'react-router-dom';
import TrelloBoard from "./TrelloBoard";
import Home from "./Home/Home"
import history from '../history'

class App extends PureComponent {

  render() {

    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/connexion" name="Login Page" render={props => <Connexion {...props}/>}/>
                <PrivateRoute path="/" exact name="Home" render={props => <Home {...props}/>}/>
                <PrivateRoute path="/:boardID" name="TrelloBoard" render={props => <TrelloBoard {...props}/>}/>
            </Switch>
        </Router>

    )
  }
}

export default App;
