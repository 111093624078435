import {setBoards, setBoardsOrder, toggleLoading} from "../actions";
import {deleteListDb, dispatchLists} from "./list_helper";
import {store} from "../store";
import history from "../history";


export function getBoards(token = null) {
    const state = store.getState()
    let current_token = token ? token : state.user.token;

    fetch(process.env.REACT_APP_API_URL_TO_DO_LIST + "/custom/getBoards", {
        method: "GET",
        headers: {"Authorization": "Bearer " + current_token}
    })
        .then(res => res.json())
        .then(
            (result) => {

                // result: Array de boards

                if (result.records) {

                    let {boardsArr, lists} = formatBoards([...result.records]);
                    let boardsObj = formatReduxBoards(boardsArr);

                    dispatchBoards(boardsObj);
                    dispatchLists(lists)
                    extractBoardsOrder(boardsArr);

                    store.dispatch(toggleLoading(false))

                    return result;
                } else {

                    store.dispatch(toggleLoading(false))

                    return null;
                }
            },
            (error) => {
                console.log(error)

                store.dispatch(toggleLoading(false))

                return null;
            }
        )
}

export function addBoardDb(board) {

    const state = store.getState()

    board.app_id = state.user.appId

    fetch(process.env.REACT_APP_API_URL_TO_DO_LIST + "/custom/setBoard", {
        method: "POST",
        headers: {"Authorization": "Bearer " + state.user.token},
        body: JSON.stringify(board)
    })
        .then(res => res.json())
        .then((result) => {
                console.log(result)

                getBoards();
            },
            (error) => {
                console.log(error)

                store.dispatch(toggleLoading(false))

                return null;
            }
        )
}

export async function deleteBoardDb(board) {

    for(let listId of board.lists ){

        await deleteListDb(listId, false)

    }

    const state = store.getState()

    fetch(process.env.REACT_APP_API_URL_TO_DO_LIST + "/records/board/" + board.id, {
        method: "DELETE",
        headers: {"Authorization": "Bearer " + state.user.token}
    }).then(res => res.json())
        .then((result) => {
                console.log(result)

                getBoards();

                history.push("/")
            },
            (error) => {
                console.log(error)

                store.dispatch(toggleLoading(false))

                return null;
            }
        )
}

export async function changeBgColorDb(boardID, color) {

    const state = store.getState()

    let body = {
        color: color
    }

    fetch(process.env.REACT_APP_API_URL_TO_DO_LIST + "/records/board/" + boardID, {
        headers: {"Authorization": "Bearer " + state.user.token},
        method: "PUT",
        body: JSON.stringify(body)
    }).then(res => res.json())
        .then(() => {
                getBoards()
            }
        )
}

/*export function setGroups(newGroups, deletedGroups) {
    let body = {
        newGroups: newGroups,
        deletedGroups: deletedGroups
    }
    let accessToken = document.cookie.replace(/(?:(?:^|.*;\s*)access_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
    fetch( /!* TODO: Mettre la bonne URL *!/, {
        headers: {"Authorization": "Bearer " + accessToken},
        method: "POST",
        body: JSON.stringify(body)
    }).then(res => res.json())
        .then(res => console.log(res))
}*/

function extractBoardsOrder(boards) {
    let boardOrder_tmp = []
    boards.forEach(board => {
        boardOrder_tmp.push(board.id)
    })
    store.dispatch(setBoardsOrder(boardOrder_tmp))
}

function dispatchBoards(boards) {
    store.dispatch(setBoards(boards))
}

function formatBoards(boardsArr) {
    let lists = {}

    boardsArr.forEach(board => {
        let tmp_list_id = [];
        board.list.forEach(list => {
            tmp_list_id.push(list.id);

            list.cards = [...list.card];

            delete list.card;

            lists[list.id] = list
        })
        board.lists = tmp_list_id;
        delete board.list
    })
    return {boardsArr, lists};
}

function formatReduxBoards(boards) {
    let boardsObj = {};
    boards.forEach(board => {
        boardsObj[board.id] = board;
    })
    return boardsObj
}
