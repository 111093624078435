import axios from "axios";
import {getQueryVariable} from "./tools_helper";
import {setUser, setToken} from "../actions";
import {store} from "../store";


export const getTokenInfo = async () => {
    let state = store.getState()
    const user = state.user;
    let newToken = getQueryVariable('access_token');
    let accessToken;

    if (newToken) {
        accessToken = newToken;

        store.dispatch(setToken(newToken));
        window.history.replaceState(null, '', "/");

    } else {
        accessToken = user.token;
    }

    try {

        return await axios({
            method: "GET",
            url: process.env.REACT_APP_BASE_URL_TOKEN + "userinfo",
            headers: {"Content-Type": "multipart/form-data", "Authorization": "Bearer " + accessToken},
        })
            .then(function (response) {

                if (response.data.mail) {

                    response.data.isAuth = true;

                    store.dispatch(setUser(response.data))

                    return true

                } else {
                    window.location = process.env.REACT_APP_BASE_URL_TOKEN + `authorize?client_id=` + process.env.REACT_APP_CLIENT_ID + `&acr_values=f05a169f9b22ff8e15541ca187f87e3d&response_type=token&redirect_uri=` + process.env.REACT_APP_BASE_URL;
                }

            })
            .catch(function (response) {
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};
