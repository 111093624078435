import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Icon from "@material-ui/core/Icon";
import Textarea from "react-textarea-autosize";
import {TextField} from "@material-ui/core";
import Select from "react-select";
import {formatMobinautesForSelect} from "../helpers/mobinaute_helper";

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const TrelloForm = React.memo(({
                                   type = "",
                                   list,
                                   text = "",
                                   onChange,
                                   closeForm,
                                   children,
                                   mobinautes,
                                   user_has_card
                               }) => {

    const [formattedMobinautes, setFormattedMobinautes] = useState([])

    useEffect(() => {
        setFormattedMobinautes(formatMobinautesForSelect(mobinautes));
    }, [])

    /** Placeholder différent en fonction du type de l'élement **/
    const placeholder = list
        ? "Entrer un titre pour cette liste..."
        : (type === "desc") ? "Entrer une description pour cette carte..."
            : (type === "title") ? "Entrer un titre pour cette carte..." : "";

    const handleFocus = e => {
        e.target.select();
    };

    const handlePersonChanged = async (e) => {
        let selectedPersons = [];

        e.forEach((person) => {
            selectedPersons.push(person.value)
        })

        onChange({
            newPersons: checkPersons(selectedPersons),
            deletedPersons: checkDeletedPersons(selectedPersons)
        })
    }

    const checkPersons = (selectedPersons) => {
        let newPersons = [];

        selectedPersons.forEach(selectedPerson => {
            if (!user_has_card.find(user => user.user_id === selectedPersons)) {
                newPersons.push(selectedPerson);
            }
        })

        return newPersons;
    }

    const checkDeletedPersons = (selectedPersons) => {
        let deletedPersons = [];

        user_has_card.forEach(user_db => {
            if (!selectedPersons.find(id => id === user_db.user_id)) {
                deletedPersons.push(user_db.user_id);
            }
        })

        return deletedPersons;
    }

    const states = [
        {
            value: "initial",
            label: "Selectionner"
        },
        {
            value: "not_started",
            label: "Non démarrée"
        },
        {
            value: "in_progress",
            label: "En cours"
        },
        {
            value: "suspended",
            label: "Suspendue"
        },
        {
            value: "finished",
            label: "Terminée"
        }
    ]
    const priorities = [
        {
            value: "initial",
            label: "Selectionner"
        },
        {
            value: "low",
            label: "Basse"
        },
        {
            value: "medium",
            label: "Moyenne"
        },
        {
            value: "high",
            label: "Elevée"
        }

    ]

    return (
        <div className={'Container'}>
            {type === "priority" ? (
                    <Select
                        name="priorities"
                        className="basic-multi-select"
                        onChange={e => onChange(e.value)}
                        options={priorities}
                    />
                )
                    : (type === "state") ? (
                        <Select
                            name="states"
                            className="basic-multi-select"
                            onChange={e => onChange(e.value)}
                            options={states}
                        />
                    )
                        : (type === "person") ? (
                            <Select
                                isMulti
                                defaultValue={text}
                                name="persons"
                                options={formattedMobinautes}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={handlePersonChanged}
                            />
                        )
                            : (type === "dueDate") ? (
                                <TextField
                                    id="datetime-local"
                                    type="datetime-local"
                                    value={text || ""}
                                    onChange={(e) => {
                                        let dateTime = e.target.value.replace("T", " ");
                                        onChange(dateTime);
                                        console.log(dateTime)
                                    }
                                    }
                                />
                            )
                                : (
                                    <Textarea className={'StyledTextArea'}
                                              name={type}
                                              placeholder={placeholder}
                                              autoFocus
                                              onFocus={handleFocus}
                                              value={text || ""}
                                              onChange={e => onChange(e)}
                                    />
                                )}
                <div className={'ButtonContainer'}>

                    {children}

                    <StyledIcon onClick={closeForm}>close</StyledIcon>
                </div>
            </div>
        );
    }
);

export default TrelloForm;
