import {CONSTANTS} from "../actions";

const initialState = {};

const listsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONSTANTS.ADD_LIST: {
            const {title, id} = action.payload;
            const newList = {
                title: title,
                id: id,
                cards: []
            };

            return {...state, [id]: newList};
        }

        case CONSTANTS.SET_LISTS: {
            return action.payload;
        }

        case CONSTANTS.ADD_CARD: {
            const {text, creator, listID, id} = action.payload;
            const newCard = {
                text,
                creator: creator,
                id: `card-${id}`,
                list: listID
            };
            const list = state[listID];

            list.cards = [...list.cards, newCard];

            //list.cards.push(`card-${id}`);
            return {...state, [listID]: list};
        }

        case CONSTANTS.EDIT_CARD: {
            const {id, newTitle, newDescription, newPriority, newState, newDueDate, newPerson, listID} = action.payload;
            const list = state[listID];

            let card = list.cards.find(element => element.id === id);
            let index = list.cards.findIndex(element => element.id === id);

            card.title = newTitle;
            card.description = newDescription;
            card.card_state = newState;
            card.card_person = newPerson;
            card.priority = newPriority;
            card.due_date = newDueDate;

            list.cards[index] = card

            return {
                ...state,
                [listID]: {
                    ...list,
                    cards: list.cards
                }
            }
        }

        case CONSTANTS.DRAG_HAPPENED:
            const {
                droppableIdStart,
                droppableIdEnd,
                droppableIndexEnd,
                droppableIndexStart,

                type
            } = action.payload;

            // draggin lists around - the listOrderReducer should handle this
            if (type === "list") {
                return state;
            }

            // in the same list
            if (droppableIdStart === droppableIdEnd) {
                const list = state[droppableIdStart];
                const card = list.cards.splice(droppableIndexStart, 1);
                list.cards.splice(droppableIndexEnd, 0, ...card);
                return {...state, [droppableIdStart]: list};
            }

            // other list
            if (droppableIdStart !== droppableIdEnd) {
                // find the list where the drag happened
                const listStart = state[droppableIdStart];
                // pull out the card from this list
                const card = listStart.cards.splice(droppableIndexStart, 1);
                // find the list where the drag ended
                const listEnd = state[droppableIdEnd];

                // put the card in the new list
                listEnd.cards.splice(droppableIndexEnd, 0, ...card);
                return {
                    ...state,
                    [droppableIdStart]: listStart,
                    [droppableIdEnd]: listEnd
                };
            }
            return state;

        case CONSTANTS.DELETE_CARD: {
            const {listID, id} = action.payload;
            let list = state[listID];
            let index = list.cards.findIndex(element => element.id === id);

            return {
                ...state,
                [listID]: {
                    ...list,
                    cards: [
                        ...list.cards.slice(0, index),
                        ...list.cards.slice(index + 1)
                    ]
                }
            };
        }

        case CONSTANTS.EDIT_LIST_TITLE: {
            const {listID, newTitle} = action.payload;
            const list = state[listID];
            list.title = newTitle;
            return {...state, [listID]: list};
        }

        case CONSTANTS.DELETE_LIST: {
            const {listID} = action.payload;
            const newState = state;
            delete newState[listID];
            return newState;
        }

        default:
            return state;
    }
};

export default listsReducer;
