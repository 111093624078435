import {setLists, toggleLoading} from "../actions";
import {getBoards} from "./board_helper";
import {store} from "../store";


export function addListDb(title, boardID) {

    const state = store.getState()

    console.log(title)

    let body = {
        title: title,
        board_id: boardID
    }

    fetch(process.env.REACT_APP_API_URL_TO_DO_LIST + "/records/list", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {"Authorization": "Bearer " + state.user.token}
    }).then(res => res.json())
        .then(
            (result) => {
                console.log(result)

                getBoards()
            },
            (error) => {
                console.log(error)

                store.dispatch(toggleLoading(false))

                return null;
            }
        )
        .catch(e => {
            console.log(e)

            store.dispatch(toggleLoading(false))

            return null;
        })
}

export async function deleteListDb(listID, needGetBoards = true) {

    const state = store.getState()

    fetch(process.env.REACT_APP_API_URL_TO_DO_LIST + "/records/list/" + listID, {
        method: "DELETE",
        headers: {"Authorization": "Bearer " + state.user.token}
    }).then(res => res.json())
        .then(res => {
            console.log(res)

            if(needGetBoards) getBoards()
        })
}

export function editListTitleDb(listID, newTitle) {

    const state = store.getState()

    let body = {
        title: newTitle
    }

    fetch(process.env.REACT_APP_API_URL_TO_DO_LIST + "/records/list/" + listID, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {"Authorization": "Bearer " + state.user.token}
    }).then(res => res.json())
        .then(res => {
            console.log(res)

            getBoards()
        })
}

export function dispatchLists(lists) {
    store.dispatch(setLists(lists))
}
