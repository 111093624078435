export function formatDate(dateDB) {
    let formatDate = "";

    if (dateDB) {
        let dateTime = dateDB.replace(" ", "T");
        formatDate = new Date(dateTime);
        formatDate = formatDate.toDateString();
    }

    return formatDate;
}

export function getQueryVariable(variable)
{
    let  query = window.location.search.substring(1);
    //console.log(query)"app=article&act=news_content&aid=160990"
    let vars = query.split("&");
    //console.log(vars) [ 'app=article', 'act=news_content', 'aid=160990' ]
    for (let i=0;i<vars.length;i++) {
        let pair = vars[i].split("=");
        //console.log(pair)[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
        if (pair[0] === variable) {
            return pair[1];
        } //si pb : enlever un =
    }
    return false;
}
