import React from "react";
import Select from "react-select";
import {useDispatch} from "react-redux";
import {changeBoardColor} from "../actions";
import "../style/select.css"

export const BgColor = (props) => {

    const styles = {
        control: styles => ({...styles, backgroundColor: 'rgba(0, 0, 0, 0.15)', width: '120px'}),
        input: styles => ({...styles, color: 'white'}),
        placeholder: styles => ({...styles, color: 'white'}),
        dropdownIndicator: styles => ({...styles, color: 'white'})
    }

    const dispatch = useDispatch();
    let boardID = props.board.id

    const colors = [
        {
            value: "#0278BF",
            label: "Bleu"
        },
        {
            value: "#AF4732",
            label: "Rouge"
        },
        {
            value: "#509839",
            label: "Vert foncé"
        },
        {
            value: "#05AECB",
            label: "Bleu turquoise"
        },
        {
            value: "#838C90",
            label: "Gris"
        },
        {
            value: "#89609E",
            label: "Violet"
        },
        {
            value: "#EA3463",
            label: "Rose"
        },
        {
            value: "#D29034",
            label: "Orange"
        },
        {
            value: "#4AC06A",
            label: "Vert clair"
        },

    ];
    const  changeColor = async e => {
        await dispatch(await changeBoardColor(boardID, e.value));
    }
    return (
        <React.Fragment>
            <Select
                placeholder={'Couleurs'}
                styles={styles}
                menuPlacement={"top"}
                options={colors}
                onChange={changeColor}
                className="custom-select"
            />
        </React.Fragment>
    );
}
