import React from "react";
import styled from "styled-components";
import TrelloButton from "./TrelloButton";

const Connexion = () => {

    const DivCenter = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
    `;

    const LinkStyleNone = styled.a`
        text-decoration: none;
    `;

    //TODO acr_values must be changed

    const button_SSO = () => { return(
                <DivCenter>
                <p>Veuillez vous connecter pour acceder au Todo-List</p>
                <LinkStyleNone
                    href={process.env.REACT_APP_BASE_URL_TOKEN+`authorize?client_id=`+process.env.REACT_APP_CLIENT_ID+`&acr_values=f05a169f9b22ff8e15541ca187f87e3d&response_type=code&redirect_uri=`+process.env.REACT_APP_BASE_URL}>
                    <TrelloButton>
                        Connexion
                    </TrelloButton>
                </LinkStyleNone>
                </DivCenter>
        );
    }
    return button_SSO();

}

export default Connexion;
