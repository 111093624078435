import React from "react";
import styled from "styled-components";


const BoardThumbnail = ({ title, color }) => {

    color = color ? color : "#EA3463";

    const Thumbnail = styled.div`
  height: 80px;
  width: 180px;
  background: ${color};
  border-color: rgba(0, 0, 0, 0.15);
  padding: 10px;
  font-family: 'Avenir', cursive;
  font-size: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px black;
`;

    const Title = styled.h4`
  color: white;
  text-decoration: none;
  margin: 0
`;

  return (
    <Thumbnail>
      <Title>{title}</Title>
    </Thumbnail>
  );
};

export default BoardThumbnail;
