import React from "react";
import TrelloButton from "./TrelloButton";
import {connect} from "react-redux";
import {addList, addCard, toggleLoading} from "../actions";
import TrelloForm from "./TrelloForm";
import TrelloOpenForm from "./TrelloOpenForm";

class TrelloCreate extends React.PureComponent {
    state = {
        formOpen: false,
        text: "",
        description: ""
    };

    openForm = () => {
        this.setState({
            formOpen: true
        });
    };

    closeForm = () => {
        this.setState({
            formOpen: false
        });
    };

    handleInputChange = e => {
        this.setState({
            text: e.target.value,
            description: e.target.value

        });
    };

    handleAddList = () => {
        const {dispatch} = this.props;
        const {text} = this.state;

        if (text) {
            this.setState({
                text: "",
                formOpen: false,
            });
            dispatch(toggleLoading(true))
            dispatch(addList(text));
        }
    };

    handleAddCard = () => {
        const {dispatch, listID, user} = this.props;
        const {text} = this.state;
        let creator = user.userFirstName + " " + user.userLastName;
        let description = null;
        if (text) {
            this.setState({
                text: "",
                formOpen: false,
            })
            dispatch(toggleLoading(true))
            dispatch(addCard(listID, text, description, creator));
        }
    };


    render() {
        const {text} = this.state;
        const {list} = this.props;
        return this.state.formOpen ? (
            list ? (
                <div className={'createList'}>
                    <TrelloForm
                        style={{display: 'block'}}
                        text={text}
                        onChange={this.handleInputChange}
                        closeForm={this.closeForm}
                        list={list}
                    >
                        <TrelloButton
                            onClick={list ? this.handleAddList : this.handleAddCard}
                        >
                            {list ? "Ajouter la liste" : "Ajouter la carte"}
                        </TrelloButton>
                    </TrelloForm>
                </div>
            ) : (
                <div className={'createCard'}>
                    <TrelloForm
                        style={{display: 'block'}}
                        text={text}
                        onChange={this.handleInputChange}
                        closeForm={this.closeForm}
                        list={list}
                    >
                        <TrelloButton
                            style={{marginLeft: '0px'}}
                            onClick={list ? this.handleAddList : this.handleAddCard}
                        >
                            {"Ajouter la carte"}
                        </TrelloButton>
                    </TrelloForm>
                </div>
            )
        ) : (
            <TrelloOpenForm list={list} onClick={this.openForm}>
                {list ? "Ajouter une liste" : "Ajouter une carte"}
            </TrelloOpenForm>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});


export default connect(mapStateToProps)(TrelloCreate);
