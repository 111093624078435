import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const StyledButton = styled(Button)`
  && {
    color: white;
    background: #5aac44;
    margin-left: 0;
  }
`;

const TrelloButton = ({ style, children, onClick }) => {
  return (
    <StyledButton variant="contained" onMouseDown={onClick} style={{...style}}>
      {children}
    </StyledButton>
  );
};


export default TrelloButton;
