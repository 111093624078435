import {store} from "../store";
import {setGroups, toggleLoading} from "../actions";

export function getShapperGroups() {
    const state = store.getState()

    fetch("https://api.shapper.com/official-api.php/records/groups?join=mobinaute", {
        method: "GET",
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + state.user.token,
        },
    })
        .then(res => res.json())
        .then((result) => {

                store.dispatch(setGroups(result.records));

                store.dispatch(toggleLoading(false))

                return result.records;
            },
            (error) => {
                console.log(error)

                store.dispatch(toggleLoading(false))

                return null;
            }
        )
}
