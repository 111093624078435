import {CONSTANTS} from "./index";
import {addCardDb, deleteCardDb, editCardDb} from "../helpers/card_helper";

export const addCard = (listID, title, description, creator) => {

    addCardDb(title, listID)

    //dispatchLoader
    return {
        type: CONSTANTS.SET_LOADING,
    };
};

export const editCard = (id, listID, newTitle, newDescription, newPriority, newState, newPersons, deletedPersons, newDueDate) => {

    editCardDb(id, newTitle, newDescription, newPriority, newState, newPersons, deletedPersons, newDueDate)

    //dispatchLoader
    return {
        type: CONSTANTS.SET_LOADING,
    };
};

// TODO: EDIT_CARD

export const deleteCard = (id, listID) => {
    deleteCardDb(id)

    //dispatchLoader
    return {
        type: CONSTANTS.SET_LOADING,
    };
};


export const setCards = (cards) => {
    console.log(cards)
    // Array de board
    return {
        type: CONSTANTS.SET_LISTS,
        payload: cards
    }
}
