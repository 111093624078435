import React, {useEffect, useState} from "react";
import TrelloForm from "./TrelloForm";
import {connect} from "react-redux";
import TrelloButton from "./TrelloButton";
import Popup from "reactjs-popup";
import {formatUserHasCardForSelect} from "../helpers/mobinaute_helper";
import {editCardDb} from "../helpers/card_helper";
import {toggleLoading} from "../actions";

const TrelloPopup = React.memo(({
                                    open,
                                    setOpen,
                                    dueDate,
                                    state,
                                    priority,
                                    title,
                                    description,
                                    creator,
                                    id,
                                    listID,
                                    dispatch,
                                    mobinautes,
                                    user_has_card
                                }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [editedPart, setEditedPart] = useState("");
    const [cardTitle, setTitle] = useState(title);
    const [cardDescription, setDescription] = useState(description);
    const [cardPriority, setPriority] = useState(priority);
    const [cardState, setState] = useState(state);
    const [defaultPersons, setDefaultPersons] = useState([])
    const [newPersons, setNewPersons] = useState([])
    const [deletedPersons, setDeletedPersons] = useState([])
    const [cardDueDate, setDueDate] = useState(dueDate);

    useEffect(() => {
        setDefaultPersons(formatUserHasCardForSelect(user_has_card, mobinautes))
    }, [mobinautes])

    const closeForm = () => {
        setIsEditing(false);
    };

    const handleChange = e => {

        if (editedPart === "desc") {
            setDescription(e.target.value);
        } else if (editedPart === "title") {
            setTitle(e.target.value);
        } else if (editedPart === "priority") {
            setPriority(e);
        } else if (editedPart === "person") {
            handleMobinautes(e)
        } else if (editedPart === "state") {
            setState(e);
        } else if (editedPart === "dueDate") {
            setDueDate(e);
        }
    };

    const handleMobinautes = (mobinautes) => {
        //filtre sur db

        console.log("user_has_card", user_has_card)//db
        console.log(mobinautes)//select

        setNewPersons(mobinautes.newPersons);
        setDeletedPersons(mobinautes.deletedPersons);
    }

    const saveCard = e => {
        e.preventDefault();

        dispatch(toggleLoading(true))

        setIsEditing(false);
        setOpen(false);

        editCardDb(id, cardTitle, cardDescription, cardPriority, cardState, newPersons, deletedPersons, cardDueDate);
    };

    const renderEditForm = (type, oldData) => {
        return (
            <TrelloForm
                type={type}
                text={oldData}
                onChange={handleChange}
                closeForm={closeForm}
                mobinautes={mobinautes}
                user_has_card={user_has_card}
            />
        );
    };

    const renderModal = () => {
        let priorityLabel = {"high": 'Elevée', "low": 'Basse', "medium": 'Moyenne'};
        let stateLabel = {
            "not_started": 'Non démarrée',
            "in_progress": 'En cours',
            "suspended": 'Suspendue',
            "finished": "Terminée"
        };
        return (
            <Popup
                style={{width: '200px'}}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                modal>
                {() => (
                    <div className="modal-container">
                        {/* Header du modal */}
                        <div style={{marginTop: '2%', display: 'flex', alignItems: 'center'}}
                             className="header-modal">
                            {(isEditing && editedPart === "title") ? (renderEditForm("title", cardTitle))
                                : (<div className={'StyledModalHeader'} onClick={() => {
                                    setIsEditing(true);
                                    setEditedPart('title');
                                }}>{cardTitle}</div>)}
                        </div>

                        {/* Contenu du modal */}
                        <div className="content">

                            {/* Description */}
                            <div style={{marginLeft: '5%'}} onClick={() => {
                                setIsEditing(true);
                                setEditedPart('desc');

                            }}>
                                <div className={'StyledModalTitleElements'}>
                                    <div className={'StyledSubTitle'}>Description :</div>
                                </div>

                            </div>
                            <div
                                style={{marginLeft: '5%'}}>{(isEditing && editedPart === "desc") ? (renderEditForm("desc", cardDescription))
                                : (<div className={'StyledModalInfo'}
                                        style={{overflow: 'scroll', outline: 'none', width: '50%'}}
                                        onClick={() => {
                                            setIsEditing(true);
                                            setEditedPart('desc');
                                        }}>{cardDescription}</div>)}
                            </div>

                            {/* Priorité */}
                            <div className={'StyledModalElements'}>
                                <div onClick={() => {
                                    setIsEditing(true);
                                    setEditedPart('priority');
                                }}>
                                    <div className={'StyledModalTitleElements'}>
                                        <div className={'StyledSubTitle'}>Priorité :</div>
                                    </div>
                                </div>
                                <div>{(isEditing && editedPart === "priority") ? (renderEditForm("priority", cardPriority))
                                    : (<div className={'StyledModalInfo'} onClick={() => {
                                        setIsEditing(true);
                                        setEditedPart('priority');
                                    }}>{priorityLabel[cardPriority]}</div>)}
                                </div>
                            </div>

                            {/* Date d'écheance */}
                            <div className={'StyledModalElements'}>
                                <div onClick={() => {
                                    setIsEditing(true);
                                    setEditedPart('dueDate');
                                }}>
                                    <div className={'StyledModalTitleElements'}>
                                        <div className={'StyledSubTitle'}>Date d'échéance :</div>
                                    </div>
                                </div>
                                <div>{(isEditing && editedPart === "dueDate") ? (renderEditForm("dueDate", cardDueDate))
                                    : (<div className={'StyledModalInfo'} onClick={() => {
                                        setIsEditing(true);
                                        setEditedPart('dueDate');
                                    }}>{cardDueDate}</div>)}
                                </div>
                            </div>

                            {/* Etat */}
                            <div className={'StyledModalElements'}>
                                <div onClick={() => {
                                    setIsEditing(true);
                                    setEditedPart('state');
                                }}>
                                    <div className={'StyledModalTitleElements'}>
                                        <div className={'StyledSubTitle'}>Etat :</div>
                                    </div>
                                </div>
                                <div>{(isEditing && editedPart === "state") ? (renderEditForm("state", cardState))
                                    : (<div className={'StyledModalInfo'} onClick={() => {
                                        setIsEditing(true);
                                        setEditedPart('state');
                                    }}>{stateLabel[cardState]}</div>)}
                                </div>
                            </div>

                            {/* Personne */}
                            <div className={'StyledModalElements'}>
                                <div onClick={() => {
                                    setIsEditing(true);
                                    setEditedPart('person');
                                }}>
                                    <div className={'StyledModalTitleElements'}>
                                        <div className={'StyledSubTitle'}>Personne :</div>
                                    </div>
                                </div>
                                <div>{(isEditing && editedPart === "person") ? (defaultPersons) ? (renderEditForm("person", defaultPersons))
                                    : (
                                        <div className={'StyledModalInfo'}
                                             onClick={() => {
                                                 setIsEditing(true);
                                                 setEditedPart('person');
                                             }}
                                        >
                                            {defaultPersons.map((person) => {
                                                return (
                                                    <p key={person.value}>{person.label}</p>
                                                )
                                            })}
                                        </div>
                                    ) : null
                                }
                                </div>
                            </div>

                            {/* Créateur */}
                            <div className={'StyledModalElements'}>
                                <div className={'StyledModalTitleElements'}>
                                    <div className={'StyledSubTitle'}>Créateur : {creator}</div>
                                </div>
                            </div>
                        </div>

                        {/* Bouton Save */}
                        <div className={'DivCenter'}>
                            <TrelloButton onClick={saveCard}>Save</TrelloButton>
                        </div>
                    </div>
                )}
            </Popup>
        );
    };

    return renderModal();
});

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(TrelloPopup);
