import { combineReducers } from "redux";
import listsReducer from "./lists.reducer";
import boardsReducer from "./boards.reducer";
import boardOrderReducer from "./board.order.reducer";
import activeBoardReducer from "./active.board.reducer";
import userReducer from "./user.reducer";
import loadingReducer from "./loading.reducer";
import groupsReducer from "./groups.reducer";

export default combineReducers({
  lists: listsReducer,
  boards: boardsReducer,
  boardOrder: boardOrderReducer,
  activeBoard: activeBoardReducer,
  user: userReducer,
  loading: loadingReducer,
  groups: groupsReducer
});
