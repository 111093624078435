import {CONSTANTS} from "../actions";

const initialState = {};

const boardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONSTANTS.ADD_LIST: {
            const {boardID, id} = action.payload;
            const board = state[boardID];
            board.lists = [...board.lists, id];

            return {...state, [boardID]: board};
        }

        case CONSTANTS.SET_BOARDS: {
            return action.payload;
        }

        case CONSTANTS.DRAG_HAPPENED: {
            const {boardID} = action.payload;
            const board = state[boardID];
            const lists = board.lists;
            const {
                droppableIndexEnd,
                droppableIndexStart,

                type
            } = action.payload;

            // draggin lists around
            if (type === "list") {
                const pulledOutList = lists.splice(droppableIndexStart, 1);
                lists.splice(droppableIndexEnd, 0, ...pulledOutList);
                board.lists = lists;

                return {...state, [boardID]: board};
            }
            return state;
        }
        case CONSTANTS.DELETE_LIST: {
            const {listID, boardID} = action.payload;
            const board = state[boardID];
            const lists = board.lists;
            let index = lists.findIndex(element => element === listID);

            return {
                ...state,
                [boardID]: {
                    ...board,
                    lists: [
                        ...lists.slice(0, index),
                        ...lists.slice(index + 1)
                    ]
                }
            };
        }

        case CONSTANTS.ADD_BOARD: {
            const {title, id} = action.payload;
            const newID = `board-${id}`;
            const newBoard = {
                id: newID,
                title,
                lists: []
            }
            return {...state, [newID]: newBoard}
        }

        case CONSTANTS.DELETE_BOARD: {
            const {boardId} = action.payload;
            const newState = {...state};
            delete newState[boardId];
            return newState;
        }

        case CONSTANTS.CHANGE_BOARD_COLOR: {
            const {boardId, color} = action.payload;
            let newState = {...state};
            let actualBoard = {...newState[boardId]};
            actualBoard.color = color
            newState[boardId] = actualBoard;
            return newState;
        }

        default:
            return state;
    }
};

export default boardsReducer;
