export * from "./lists.actions";
export * from "./cards.actions";
export * from "./boards.actions";
export * from "./user.actions";
export * from "./loading.actions";
export * from "./groups.actions";

export const CONSTANTS = {
    ADD_CARD: "ADD_CARD",
    DELETE_CARD: "DELETE_CARD",
    EDIT_CARD: "EDIT_CARD",
    ADD_LIST: "ADD_LIST",
    DELETE_LIST: "DELETE_LIST",
    EDIT_LIST_TITLE: "EDIT_LIST_TITLE",
    DRAG_HAPPENED: "DRAG_HAPPENED",
    ADD_BOARD: "ADD_BOARD",
    DELETE_BOARD: "DELETE_BOARD",
    CHANGE_BOARD_COLOR: "CHANGE_BOARD_COLOR",
    SET_ACTIVE_BOARD: "SET_ACTIVE_BOARD",
    SET_BOARDS: "SET_BOARDS",
    SET_LISTS: "SET_LISTS",
    FILTER_PRIORITY_BOARD: "FILTER_PRIORITY_BOARD",
    SET_BOARD_ORDER: "SET_BOARD_ORDER",
    SET_USER: "SET_USER",
    SET_TOKEN:"SET_TOKEN",
    SET_LOADING:"SET_LOADING",
    UNSET_LOADING:"UNSET_LOADING",
    SET_GROUPS:"SET_GROUPS"
};
