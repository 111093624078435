import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
//import Store from "./store";
import {store, persistor} from "./store";
import * as serviceWorker from "./serviceWorker";
import {createGlobalStyle} from "styled-components";
import {PersistGate} from "redux-persist/integration/react";
import CheckCredentials from "./components/CheckCredentials";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./style/trelloCard.css"
import "./index.css";
import "./style/Home.css";
import "./style/trelloBoard.css"
import "./style/trelloPopup.css"
import "./style/trelloList.css"
import "./style/trelloForm.css"
import "./style/trelloFilter.css"
import "./style/trelloCreate.css"

//const {persistor, store} = Store();

const GlobalStyle = createGlobalStyle`
  html {
    background-color: #EA3463;
    box-sizing: border-box;
    transition: all 0.5s ease-in;
    margin: 0;
    padding: 0;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
`;

if(process.env.NODE_ENV === "production"){
    console.log = () => {};
} else {
    window.store = store
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GlobalStyle />
      <CheckCredentials />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
