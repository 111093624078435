import { CONSTANTS } from "../actions";

const initialState = false;

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONSTANTS.SET_LOADING: {
            return true;
        }
        case CONSTANTS.UNSET_LOADING:
            return false
        default:
            return false;
    }
};

export default loadingReducer;
