import React from "react";
import {Link} from "react-router-dom";
import logo from "../../images/logo.png";


export const HomeHeader = (props) => {

    return (
        <div className={'header-home'}>
            <Link style={{padding: 'Opx', margin: '0px'}} to="/">
                <img src={logo} alt={'logo'} style={{
                    height: '35px',
                }}/>
            </Link>

            {props.children}
        </div>
    )
};
