import {getBoards} from "./board_helper";
import {store} from "../store";


export function addCardDb(title, listID) {

    const state = store.getState()

    let body = {
        title: title,
        description: null,
        priority: null,
        card_state: null,
        card_person: null,
        due_date: null,
        list_id: listID
    }

    fetch(process.env.REACT_APP_API_URL_TO_DO_LIST + "/custom/setCard", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {"Authorization": "Bearer " + state.user.token}
    }).then(res => res.json())
        .then(
            (result) => {
                console.log(result)

                getBoards();
            },
            (error) => {
                console.log(error)
                return null;
            }
        )

    getBoards();
}

export function deleteCardDb(cardID) {

    const state = store.getState()

    fetch(process.env.REACT_APP_API_URL_TO_DO_LIST + "/records/card/" + cardID, {
        method: "DELETE",
        headers: {"Authorization": "Bearer " + state.user.token}
    }).then(res => res.json())
        .then(res => {
            console.log(res)
            getBoards()
        })
}

export function editCardDb(cardId, newTitle, newDescription, newPriority, newState, newPersons, deletedPersons, newDueDate) {

    const state = store.getState()

    let body = {
        title: newTitle,
        description: newDescription,
        priority: newPriority,
        card_state: newState,
        due_date: newDueDate,
        newPersons: newPersons,
        deletedPersons: deletedPersons
    }

    // TODO: Mettre la bonne URL
    fetch(process.env.REACT_APP_API_URL_TO_DO_LIST + "/custom/setCard/" + cardId, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {"Authorization": "Bearer " + state.user.token}
    })
        .then(res => res.json())
        .then(res => {
            console.log(res)

            getBoards()
        })
}
