import {CONSTANTS} from "../actions";

const groupsReducer = (state = [], action) => {
    switch (action.type) {
        case CONSTANTS.SET_GROUPS: {
            return action.payload;
        }
        default:
            return state;
    }
};

export default groupsReducer;
