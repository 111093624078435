
export function formatMobinautesForSelect(mobinautes) {
    let formattedMobinautes = []

    if (mobinautes) {
        mobinautes.forEach((mobinaute) => {
            let formatMobinaute = {
                value: mobinaute.id,
                label: mobinaute.firstname + " " + mobinaute.lastname + " (" + mobinaute.login + ")"
            }

            formattedMobinautes.push(formatMobinaute)
        })

        return formattedMobinautes;
    }

}

export function formatUserHasCardForSelect(user_has_card, mobinautes) {
    let formattedMobinautes = []

    if (user_has_card) {
        user_has_card.forEach(user => {
            let mobinaute = mobinautes.find(mobinaute => mobinaute.id === user.user_id);


            if (mobinaute) {
                let formatMobinaute = {
                    value: mobinaute.id,
                    label: mobinaute.firstname + " " + mobinaute.lastname + " (" + mobinaute.login + ")"
                }

                formattedMobinautes.push(formatMobinaute)
            }
        })

        return formattedMobinautes;
    }
}
