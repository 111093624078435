import { CONSTANTS } from "../actions";

const initialState = {
    userId: null,
    userFirstName: null,
    userLastName: null,
    isAuth: false,
    appId: null,
    token: '',
    groups: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case CONSTANTS.SET_USER: {
            return {
                ...state,
                userId:action.payload.userId,
                userFirstName:action.payload.userFirstName,
                userLastName:action.payload.userLastName,
                isAuth:action.payload.isAuth,
                appId:action.payload.appId,
                groups:action.payload.groups,
            };
        }
        case CONSTANTS.SET_TOKEN:
            return {
                ...state,
                token: action.payload.token
            }
        default:
            return state;
    }
};

export default userReducer;
